import {
  API,
  Asset,
  Item,
  Kit,
  KitCollection,
  KitVersion,
  Portal,
  Section,
  Space,
} from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  hostname: string;
  spaceId?: number;
  kitCollectionId?: string;
  kitId?: string;
  sectionId?: string;
  itemId?: string;
  galleryId?: string;
  assetId?: string;
  portalId?: string;
  version?: number;
  assetToken?: string;
};

type Result = {
  portal?: Portal;
  space: Space;
  kit?: Kit;
  kitCollection?: KitCollection;
  kitVersion?: KitVersion;
  section?: Section;
  item?: Item;
  gallery?: Item;
  asset?: Asset;
  version: number;
};
type NormalizedResult = {
  portal?: string;
  space: number;
  kitCollection?: string;
  kit?: string;
  kitVersion?: string;
  section?: string;
  item?: string;
  gallery?: string;
  asset?: string;
  version: number;
};

const [useIdentifyContext, identifyContext] = createQueryAction<Args, Result, NormalizedResult>(
  {
    entity: "spaces",
    action: "identifyDomain",
    denormalize: {
      entity: null,
      portal: "portals",
      kitCollection: "kitCollections",
      space: "spaces",
      kit: "kits",
      kitVersion: "kitVersions",
      section: "sections",
      item: "items",
      gallery: "items",
      asset: "assets",
    },
  },
  async ({ args }) => {
    const result = await API.call<NormalizedResult>({
      endpoint: `identify`,
      method: "GET",
      entity: API.Entity.contextIdentification,
      query: {
        hostname: args.hostname,
        space_id: args.spaceId,
        kit_collection_identifier: args.kitCollectionId,
        portal_identifier: args.portalId,
        kit_identifier: args.kitId,
        section_identifier: args.sectionId,
        item_identifier: args.itemId,
        asset_identifier: args.assetId,
        v: args.version,
        asset_token: args.assetToken,
      },
    });

    return result;
  }
);

export default useIdentifyContext;
export { identifyContext };
